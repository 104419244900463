import React from 'react';
import {InputText} from 'primereact/inputtext';
import {AppContext, MessageService, OrderItemsComponent, ToastService, TwoDialog} from 'two-app-ui';
import {
  Location,
  LocationReference,
  Address,
  PaOrder,
  PaOrderPatch,
  PaProductDefinition,
  OrderItem,
  Field,
  PaContact,
  UserReference,
  Company,
  FittingType,
  DropdownOption,
  FittingOptions,
  FittingOptionReferenceType,
  FreightOptions,
  CompanyFreightOption,
  Made2FreightOptions,
  PickUpFreightOptions,
  StorageFreightOptions,
  ThirdPartyFreightOptions,
  SharedLocation,
  QueryParameter,
  SharedLocationAggregate,
  PaSharedLocation,
} from 'two-core';
import {Toast} from 'primereact/toast';
import OrdersService from '../../services/OrdersService';
import './Order.scss';
import {messages} from '../../config/messages';
import {Dropdown, DropdownChangeParams} from 'primereact/dropdown';
import LocationsService from '../../services/LocationsService';
import ProductsService from '../../services/ProductsService';
import {Button} from 'primereact/button';
import {RequestJobDialog} from '../Job/RequestJobDialog/RequestJobDialog';
import M2OUsersService from '../../services/M2OUsersService';
import ContactsService from '../../services/ContactsService';
import {localStorageAttributes} from '../../config/localStorageAttributes';
import {InputSwitch} from 'primereact/inputswitch';
import CompaniesService from '../../services/CompaniesService';
import {NewProductDefinitionDialog} from '../Job/ReviewMeasureDialog/NewProductDefinitionDialog';
import {SharedLocationsService} from '../../services/SharedLocationsService';
import {thirdPartyFreightServicesOptions} from '../../config/values';

interface Props {
  showDialog: boolean;
  onHide: () => void;
  toast: React.RefObject<Toast>;
  order?: PaOrder; //existing order
  newOrder?: PaOrder; // new order
  forceCurrentProductDefinition?: boolean;
}

interface State {
  orderPatch: PaOrderPatch;
  loading: boolean;
  contacts: PaContact[];
  selectedOwner?: PaContact;
  locations: Location[];
  locationGroups?: AddressGroup[];
  selectedLocation?: Location;
  orderProductDefinitions: PaProductDefinition[];
  newestProductDefinitions?: PaProductDefinition[];
  clonedItems: OrderItem[];
  invalidItemMessagesMap?: Map<number, string[]>;
  showRequestJobDialog: boolean;
  updatedOrder?: PaOrder;
  companyFittingTypes: string[];
  companyFreightOptions: CompanyFreightOption[];
  selectedFittingProvider: string;
  orderFreightOptions?: FreightOptions;
  fitterContacts: PaContact[];
  fittingProviderCompanies: Company[];
  fittingProviderOptions: DropdownOption[];
  fittingProviderLocations: Location[];
  companyLocations: Location[];
  showNewProductDefinitionDialog: boolean;
  forceCurrentProductDefinition: boolean;
  storageSharedLocations: PaSharedLocation[];
  pickUpSharedLocations: PaSharedLocation[];
}

interface AddressGroup {
  label: string;
  items: Location[];
}

class AddEditOrderDialog extends React.Component<Props, State> {
  static contextType = AppContext;

  ordersService: OrdersService | null = null;
  toastService: ToastService | null = null;
  locationsService: LocationsService | null = null;
  sharedLocationsService?: SharedLocationsService;
  productsService: ProductsService | null = null;
  usersService: M2OUsersService | null = null;
  contactsService: ContactsService | null = null;
  companiesService: CompaniesService | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      orderPatch: {},
      loading: false,
      showRequestJobDialog: false,
      contacts: [],
      locations: [],
      orderProductDefinitions: [],
      clonedItems: [],
      invalidItemMessagesMap: new Map<number, string[]>(),
      companyFittingTypes: [],
      companyFreightOptions: [],
      selectedFittingProvider: props.order?.fitting_options?.reference.id ?? '',
      fitterContacts: [],
      fittingProviderCompanies: [],
      fittingProviderOptions: [],
      fittingProviderLocations: [],
      companyLocations: [],
      showNewProductDefinitionDialog: false,
      forceCurrentProductDefinition: false,
      storageSharedLocations: [],
      pickUpSharedLocations: [],
    };

    this.onSave = this.onSave.bind(this);
    this.onSaveAndSubmit = this.onSaveAndSubmit.bind(this);
    this.onHide = this.onHide.bind(this);
    this.onShow = this.onShow.bind(this);
    this.updateOrder = this.updateOrder.bind(this);
    this.createOrder = this.createOrder.bind(this);
    this.onInputOwnerChange = this.onInputOwnerChange.bind(this);
    this.contactValueTemplate = this.contactValueTemplate.bind(this);
    this.contactOptionTemplate = this.contactOptionTemplate.bind(this);
    this.onInputShippingAddressChange = this.onInputShippingAddressChange.bind(this);
    this.addressSelectedOptionTemplate = this.addressSelectedOptionTemplate.bind(this);
    this.addressOptionTemplate = this.addressOptionTemplate.bind(this);
    this.onItemsChange = this.onItemsChange.bind(this);
    this.setInvalidItemMessagesMap = this.setInvalidItemMessagesMap.bind(this);
    this.validate = this.validate.bind(this);
    this.onRequestJobHide = this.onRequestJobHide.bind(this);
    this.onSaveAndRequestJob = this.onSaveAndRequestJob.bind(this);
    this.onInputFittingChange = this.onInputFittingChange.bind(this);
    this.getMade2FitLocation = this.getMade2FitLocation.bind(this);
    this.onFreightOptionsChange = this.onFreightOptionsChange.bind(this);
  }

  componentDidMount() {
    this.ordersService = this.context.ordersService;
    this.toastService = this.context.toastService;
    this.locationsService = this.context.locationsService;
    this.sharedLocationsService = this.context.sharedLocationsService;
    this.productsService = this.context.productsService;
    this.usersService = this.context.usersService;
    this.contactsService = this.context.contactsService;
    this.companiesService = this.context.companiesService;
  }

  async loadLocations(currentCompanyId: string) {
    return this.locationsService?.getLocationsByCompanyId(currentCompanyId, false).then(data => {
      const locations = (data.records ?? []) as Location[];
      const order = this.props.order;
      const shippingAddress = order?.shipping_address;
      let selectedLocation = undefined;
      if (shippingAddress) {
        if ((order.shipping_address as Address).street) {
          selectedLocation = locations.find(value => value.address.id === order.shipping_address?.id);
        } else {
          selectedLocation = locations.find(value => value.id === (order.shipping_address as LocationReference).id);
        }
      }
      return {
        selectedLocation: selectedLocation,
        locations: locations,
      };
    });
  }

  async loadSharedLocations(currentCompanyId: string) {
    try {
      const filters = [
        JSON.stringify({
          field: 'company_id',
          value: currentCompanyId,
        }),
      ];
      const aggregate: SharedLocationAggregate[] = ['location'];
      const params: QueryParameter = {
        filters: filters,
        aggregate: aggregate,
      };
      const sharedLocationsResult = await this.sharedLocationsService?.getSharedLocations(params);
      const sharedLocations = sharedLocationsResult?.records as PaSharedLocation[];
      const storageSharedLocations = sharedLocations?.filter(sl => sl.purpose === 'Storage') ?? [];
      const pickUpSharedLocations = sharedLocations?.filter(sl => sl.purpose === 'Pick Up') ?? [];
      return {storageSharedLocations, pickUpSharedLocations};
    } catch (e) {
      console.error('Failed loading shared locations:', e);
      return undefined;
    }
  }

  async loadFittingLocations(
    companyLocations: Location[],
    fittingProviderCompanies: Company[],
    order?: PaOrder
  ): Promise<
    | {
        locationGroups?: AddressGroup[];
        fittingProviderOptions: DropdownOption[];
        selectedLocation?: Location;
      }
    | undefined
  > {
    if (order?.fitting_options?.type) {
      if (order?.fitting_options?.type === '3rd party') {
        const fittingProviderOptions = fittingProviderCompanies.map(company => {
          return {
            label: company.name,
            value: company.id as string,
          };
        });
        return this.locationsService?.getLocationsByCompanyId(order?.fitting_options?.reference.id, true).then(data => {
          const fittersLocations = data.records as Location[];
          const allLocations = companyLocations.concat(fittersLocations);
          const location = allLocations.find(l => l.id === order?.shipping_address.id);

          const addressGroups = [
            {
              label: `your location${companyLocations.length > 1 ? 's' : ''}`,
              items: companyLocations,
            },
            {
              label: `fitting provider's location${fittersLocations.length > 1 ? 's' : ''}`,
              items: fittersLocations,
            },
          ];

          return {
            locationGroups: addressGroups,
            fittingProviderOptions: fittingProviderOptions,
            selectedLocation: location,
          };
        });
      } else if (order?.fitting_options?.type === 'internal') {
        const fittingProviderOptions = this.state.fitterContacts.map(contact => {
          return {
            label: `${contact.first_name} ${contact.last_name}`,
            value: contact.id as string,
          };
        });
        return {
          fittingProviderOptions: fittingProviderOptions,
        };
      }
    }
    return undefined;
  }

  async loadContacts(currentRole?: string | null) {
    if (currentRole === 'admin') {
      const sortBy = JSON.stringify({
        field: 'first_name',
        direction: 'ASC',
      });

      return this.contactsService
        ?.getContacts({orderBys: [sortBy]})
        .then(data => {
          const contacts = (data.records as PaContact[]) ?? [];
          const currentOwnerId =
            this.props.order?.owner_contact_ref?.contact_id ?? this.props.newOrder?.owner_contact_ref?.contact_id;
          const fitterContacts = contacts.filter(contact => contact.role === 'fitter');
          const selectedOwner = contacts.find(
            contact => contact.id === currentOwnerId ?? this.usersService?.myContact?.id
          );

          return {
            contacts,
            fitterContacts,
            selectedOwner,
          };
        })
        .catch(error => {
          this.toastService?.showError(this.props.toast, 'Sorry, loading contacts failed. Please, try again.');
          console.log(error);
          return {
            contacts: [],
          };
        });
    } else {
      const myContact = JSON.parse(localStorage.getItem(localStorageAttributes.myContact) ?? '{}');
      return {
        contacts: [myContact],
        fitterContacts: [],
        selectedOwner: myContact,
      };
    }
  }

  async loadProductDefinitions(revisionId?: number, ownerId?: string) {
    const orderProductDefinitionsPromise = this.productsService!.getProductsDefinitions(revisionId, ownerId);
    const newestProductDefinitionsPromise = this.productsService!.getProductsDefinitions(undefined, ownerId);
    return Promise.all([orderProductDefinitionsPromise, newestProductDefinitionsPromise]).then(results => {
      const [orderResult, newestResult] = results;
      const orderProductDefinitions = (orderResult.records as PaProductDefinition[]) ?? [];
      const newestProductDefinitions = (newestResult.records as PaProductDefinition[]) ?? [];
      return {
        orderProductDefinitions,
        newestProductDefinitions,
      };
    });
  }

  onShow() {
    let availableFittingTypes: string[] = [];
    const currentCompanyString = localStorage.getItem(localStorageAttributes.currentCompany) ?? '[]';
    const currentCompany = JSON.parse(currentCompanyString) as Company;
    const companyFittingTypesText = localStorage.getItem(localStorageAttributes.fittingTypes) as string;
    if (companyFittingTypesText) {
      availableFittingTypes = companyFittingTypesText.split(',');
    }
    const companyFreightOptionsText = currentCompany.freight_options as string | undefined;
    const companyFreightOptions: CompanyFreightOption[] = (companyFreightOptionsText?.split(',') as
      | CompanyFreightOption[]
      | undefined) ?? ['Made 2 Freight - Flat'];
    this.setState({
      loading: true,
    });
    const order = this.props.order;
    const newOrder = this.props.newOrder;
    const locationGroups = this.state.locationGroups;
    const revisionId = order?.revision_id ?? newOrder?.revision_id;
    const ownerId = order?.owner ?? newOrder?.owner;
    const currentRole = localStorage.getItem(localStorageAttributes.currentRole);
    const forceCurrentProductDefinition =
      this.props.forceCurrentProductDefinition ?? this.state.forceCurrentProductDefinition;
    let orderFreightOptions: FreightOptions | undefined;
    if (order) {
      orderFreightOptions = order.freight_options;
    } else {
      if (companyFreightOptions.includes('Made 2 Freight - Flat')) {
        orderFreightOptions = {freight_type: 'Made 2 Freight', pricing: 'Flat'} as Made2FreightOptions;
      }
    }

    const loads = [
      this.loadLocations(currentCompany.id!),
      this.loadProductDefinitions(revisionId, ownerId),
      this.loadContacts(currentRole),
      this.loadSharedLocations(currentCompany.id!),
    ];

    Promise.all(loads)
      .then(async results => {
        const locationsResult = results[0] as {
          selectedLocation?: Location;
          locations: Location[];
        };
        const productDefinitionsResult = results[1] as {
          orderProductDefinitions: PaProductDefinition[];
          newestProductDefinitions: PaProductDefinition[];
        };
        const contactsResult = results[2] as {
          contacts?: PaContact[];
          fitterContacts?: PaContact[];
          selectedOwner?: PaContact;
        };
        const sharedLocationsResult = results[3] as {
          storageSharedLocations?: SharedLocation[];
          pickUpSharedLocations?: SharedLocation[];
        };
        const companyLocations = locationsResult?.locations ?? [];
        const fittingProviderCompanies = currentCompany.fitting_providers!;
        const fittingLocationsResult = await this.loadFittingLocations(
          companyLocations,
          fittingProviderCompanies,
          order
        );
        const clonedItems = this.cloneItems(order?.items ?? []);
        this.setState({
          selectedLocation: fittingLocationsResult?.selectedLocation ?? locationsResult?.selectedLocation,
          companyLocations: companyLocations,
          locations: locationsResult?.locations ?? [],
          fittingProviderCompanies: fittingProviderCompanies,
          clonedItems: clonedItems,
          companyFittingTypes: availableFittingTypes,
          companyFreightOptions: companyFreightOptions,
          orderProductDefinitions: productDefinitionsResult.orderProductDefinitions,
          orderFreightOptions: orderFreightOptions,
          newestProductDefinitions: productDefinitionsResult.newestProductDefinitions,
          contacts: contactsResult.contacts ?? [],
          fitterContacts: contactsResult.fitterContacts ?? [],
          selectedOwner: contactsResult.selectedOwner,
          fittingProviderOptions: fittingLocationsResult?.fittingProviderOptions ?? [],
          locationGroups: fittingLocationsResult?.locationGroups ?? locationGroups,
          forceCurrentProductDefinition: forceCurrentProductDefinition,
          storageSharedLocations: sharedLocationsResult.storageSharedLocations ?? [],
          pickUpSharedLocations: sharedLocationsResult.pickUpSharedLocations ?? [],
        });
      })
      .catch(error => {
        console.error('Failed Loading Data:' + error);
        this.toastService?.showError(this.props.toast, 'Failed loading data, please refresh and try again.');
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  /**
   * We need to create deep copy of items
   */
  cloneItems(items: OrderItem[]) {
    const clonedItems = (structuredClone(items) as OrderItem[]).map(item => {
      const fields: Field[] = item.fields.map(field => {
        const values = field.values.map(fieldValue => {
          if (fieldValue.sub_fields) {
            fieldValue.sub_fields = fieldValue.sub_fields.map(subField => {
              return new Field(subField);
            });
          }
          return fieldValue;
        });
        return new Field({...field, values: values});
      });
      item.fields = fields;
      return new OrderItem(item);
    });
    return clonedItems;
  }

  setInvalidItemMessagesMap(newInvalidItemMessagesMap: Map<number, string[]>) {
    this.setState({
      invalidItemMessagesMap: newInvalidItemMessagesMap,
    });
  }

  onHide() {
    this.setState({
      clonedItems: [],
      orderPatch: {},
      selectedLocation: undefined,
      orderProductDefinitions: [],
      newestProductDefinitions: undefined,
      showNewProductDefinitionDialog: false,
      forceCurrentProductDefinition: false,
    });
    this.props.onHide();
  }

  onRequestJobHide() {
    this.setState({
      showRequestJobDialog: false,
    });
    this.onHide();
  }

  async onSave(requestJob: boolean) {
    const {order} = this.props;
    const {orderPatch} = this.state;
    if (Object.keys(orderPatch).length) {
      if (this.validate()) {
        if (order?.id) {
          this.updateOrder(requestJob);
        } else {
          this.createOrder(requestJob);
        }
      }
    } else {
      //nothing changed -> close the dialog
      this.onHide();
    }
  }

  validate(): boolean {
    const order = this.props.order ?? this.props.newOrder;
    const {orderPatch, companyFittingTypes, invalidItemMessagesMap} = this.state;

    const errors: string[] = [];
    const itemsErrors = [];
    const reference = orderPatch.reference ?? order!.reference;
    if (!reference?.length) {
      errors.push('Reference field is empty.');
    }
    if (localStorage.getItem(localStorageAttributes.currentRole) === 'admin' && !this.state.selectedOwner) {
      errors.push('Owner not chosen.');
    }
    const stage = orderPatch.stage ?? order!.stage;
    if (!stage) {
      errors.push('Stage is empty.');
    }
    if (companyFittingTypes.length > 0) {
      const fittingOptions = orderPatch.fitting_options ?? order?.fitting_options;
      if (
        (fittingOptions?.type === 'internal' || fittingOptions?.type === '3rd party') &&
        (!fittingOptions.reference || !fittingOptions.reference.id || !fittingOptions.reference.type)
      ) {
        errors.push('Fitting provider not chosen.');
      }
    }
    const shippingAddress = orderPatch.shipping_address ?? order!.shipping_address;
    if (!shippingAddress) {
      errors.push('Shipping address is empty.');
    }
    const items = orderPatch.items ?? order!.items;
    if (!items?.length) {
      errors.push('Order must have at least one item.');
    }
    if (invalidItemMessagesMap?.size) {
      for (const [itemIndex, messages] of Array.from(invalidItemMessagesMap.entries())) {
        itemsErrors.push(
          <div>
            Item {itemIndex} is invalid:
            {messages.map((error, index) => {
              return <li key={index}>{error}</li>;
            })}
          </div>
        );
      }
    }

    if (errors.length || itemsErrors.length) {
      const errorText = (
        <>
          {!!errors.length && (
            <div>
              Form is invalid:
              {errors.map((error, index) => {
                return <li key={index}>{error}</li>;
              })}
            </div>
          )}
          {!!itemsErrors.length && itemsErrors}
        </>
      );
      this.toastService?.showError(this.props.toast, errorText as JSX.Element);
      MessageService.sendMessage(messages.orderCanNotBeSaved);
      return false;
    }
    return true;
  }

  async onSaveAndSubmit() {
    const {orderProductDefinitions, newestProductDefinitions, forceCurrentProductDefinition} = this.state;
    if (
      !forceCurrentProductDefinition &&
      orderProductDefinitions?.length &&
      newestProductDefinitions?.length &&
      orderProductDefinitions[0].revision_id !== newestProductDefinitions[0].revision_id
    ) {
      this.setState({showNewProductDefinitionDialog: true});
    } else {
      if (this.validate()) {
        const newOrderPatch: PaOrderPatch = {
          ...this.state.orderPatch,
          stage: 'New',
        };
        if (forceCurrentProductDefinition) {
          newOrderPatch.revision_id = newestProductDefinitions![0].revision_id;
        }
        this.setState({orderPatch: newOrderPatch}, () => this.onSave(false));
      }
    }
  }

  async onSaveAndRequestJob() {
    this.onSave(true);
  }

  convertContactToUserRef(contact: PaContact): UserReference {
    return {
      user_id: contact.user_id ?? '',
      contact_id: contact.id,
      label: `${contact.first_name} ${contact.last_name}`,
    };
  }

  async getMade2FitLocation(state?: string) {
    this.setState({loading: true});
    return this.companiesService
      ?.getMade2FitLocation(state)
      .then((location: Location) => {
        return location;
      })
      .catch(() => {
        this.toastService?.showError(this.props.toast, 'Sorry, made2fit location load failed, please try again.');
        this.setState({loading: false});
      });
  }

  async createOrder(requestJob: boolean) {
    const orderPatch = {...this.state.orderPatch};
    // TODO: revive once FF2 is gone => as FF2 does not allow to send to somebody else's shipping address
    // if (orderPatch.fitting_options?.type === 'made2fit') {
    //   const state = localStorage.getItem(
    //     localStorageAttributes.currentCompanyState
    //   ) as string;
    //   const made2FitLocation = await this.getMade2FitLocation(state);
    //   if (!made2FitLocation) {
    //     return;
    //   }
    //   const address = made2FitLocation.address;
    //   orderPatch.shipping_address = {
    //     address: `${address.street}, ${address.suburb}, ${address.state}, ${address.country}`,
    //     id: made2FitLocation.id!,
    //   };
    // }
    this.setState({loading: true});

    const owner_ref: UserReference = this.convertContactToUserRef(
      this.state.selectedOwner ? this.state.selectedOwner : this.usersService!.myContact!
    );

    const newOrder = {
      ...this.props.newOrder,
      revision_id: this.state.orderProductDefinitions[0].revision_id,
      owner_contact_ref: owner_ref,
      ...orderPatch,
    } as PaOrder;
    if (this.state.orderFreightOptions) {
      newOrder.freight_options = this.state.orderFreightOptions;
    }

    return this.ordersService
      ?.createOrder(localStorage.getItem(localStorageAttributes.currentCompanyId) ?? ' ', newOrder)
      .then(order => {
        this.toastService?.showSuccess(this.props.toast, 'Order created successfully.');
        this.onHide();
        if (requestJob) {
          this.setState({showRequestJobDialog: true, updatedOrder: order});
        } else {
          MessageService.sendMessage(messages.orderCreated);
        }
      })
      .catch(error => {
        this.toastService?.showError(
          this.props.toast,
          error ?? 'Sorry, order creation failed, please check your connection and try again.'
        );
        console.error('error: ' + error);
        this.setState({loading: false});
      });
  }

  async updateOrder(requestJob: boolean) {
    const order = this.props.order!;
    const {orderPatch, selectedOwner} = this.state;
    // TODO: revive once FF2 is gone => as FF2 does not allow to send to somebody else's shipping address
    // if (orderPatch.fitting_options?.type === 'made2fit') {
    //   const state = localStorage.getItem(
    //     localStorageAttributes.currentCompanyState
    //   ) as string;
    //   const made2FitLocation = await this.getMade2FitLocation(state);
    //   if (!made2FitLocation) {
    //     return;
    //   }
    //   const address = made2FitLocation.address;
    //   orderPatch.shipping_address = {
    //     address: `${address.street}, ${address.suburb}, ${address.state}, ${address.country}`,
    //     id: made2FitLocation.id!,
    //   };
    // }
    this.setState({loading: true});
    if (selectedOwner && selectedOwner.id && selectedOwner.id !== order.owner_contact_ref?.contact_id) {
      orderPatch.owner_contact_ref = this.convertContactToUserRef(selectedOwner);
    }
    if (this.state.orderFreightOptions) {
      orderPatch.freight_options = this.state.orderFreightOptions;
    }

    return this.ordersService
      ?.updateOrder(localStorage.getItem(localStorageAttributes.currentCompanyId) ?? ' ', order.id!, orderPatch)
      .then(() => {
        this.toastService?.showSuccess(this.props.toast, `Order ${order.id!} ${order.reference} updated successfully.`);
        this.onHide();
        if (requestJob) {
          this.setState({showRequestJobDialog: true, updatedOrder: order});
        } else {
          MessageService.sendMessage(messages.orderUpdated);
        }
      })
      .catch(e => {
        this.toastService?.showError(
          this.props.toast,
          e ??
            `Sorry, updating ${order.id} ${order.reference} failed. Please check your internet connection and try again.`
        );
        this.setState({loading: false});
      });
  }

  onInputReferenceChange(value: string) {
    const orderPatch = this.state.orderPatch;
    const updatedOrder = {
      ...orderPatch,
      reference: value,
    };
    this.setState({orderPatch: updatedOrder});
  }

  onInputDeliveryNoteChange(value: string) {
    const orderPatch = this.state.orderPatch;
    const updatedOrder: PaOrderPatch = {
      ...orderPatch,
      freight_options: {
        freight_type: 'made2freight',
        delivery_note: value,
      },
    };
    this.setState({orderPatch: updatedOrder});
  }

  onInputOwnerChange = (e: DropdownChangeParams) => {
    this.setState({selectedOwner: e.value});
  };

  onInputFittingChangeDrop = (value: DropdownChangeParams) => {
    this.onInputFittingChange(value.target.value);
  };

  onInputFittingChange = (value: string | undefined) => {
    let newFitterOptions: DropdownOption[] = [];
    if (value === '3rd party') {
      newFitterOptions = this.state.fittingProviderCompanies?.map(company => {
        return {
          label: company.name,
          value: company.id as string,
        };
      });
    } else if (value === 'internal') {
      newFitterOptions = this.state.fitterContacts.map(contact => {
        return {
          label: `${contact.first_name} ${contact.last_name}`,
          value: contact.id as string,
        };
      });
    }

    //todo: add reset selected shipping address if selected and not in the list => e.g.: changing from 3rd party to internal and the address is the fitting providers

    this.setState({
      locationGroups: undefined,
      fittingProviderOptions: newFitterOptions,
      orderPatch: {
        ...this.state.orderPatch,
        fitting_options: {
          type: value as FittingType,
          reference: {
            type: 'contact',
            label: '',
            id: '',
          },
        },
      },
    });
  };

  onFittingProviderChange = async (option: DropdownChangeParams) => {
    const orderFitting: FittingType | undefined =
      this.state.orderPatch.fitting_options?.type ?? this.props.order?.fitting_options?.type;
    const selectedOption = this.state.fittingProviderOptions.find(o => o.value === option.value);
    const orderFittingReferenceType: FittingOptionReferenceType = orderFitting === '3rd party' ? 'company' : 'contact';

    let newLocationGroups: AddressGroup[] | undefined;
    if (orderFitting === '3rd party') {
      await this.locationsService?.getLocationsByCompanyId(option.value, true).then(data => {
        const fittersLocations = data.records as Location[];
        newLocationGroups = [
          {
            label: `your location${this.state.companyLocations.length > 1 ? 's' : ''}`,
            items: this.state.companyLocations,
          },
          {
            label: `fitting provider's location${fittersLocations.length > 1 ? 's' : ''}`,
            items: fittersLocations,
          },
        ];
      });
    }

    let newFittingOptions: FittingOptions | undefined;
    if (orderFitting) {
      newFittingOptions = {
        type: orderFitting,
        reference: {
          type: orderFittingReferenceType,
          label: selectedOption?.label as string,
          id: option.value,
        },
      };
    }

    this.setState({
      locationGroups: newLocationGroups,
      selectedFittingProvider: option.value,
      orderPatch: {
        ...this.state.orderPatch,
        fitting_options: newFittingOptions,
      },
    });
  };

  onFreightOptionsChange(
    orderFreightOptionsPatch: Partial<
      FreightOptions | PickUpFreightOptions | StorageFreightOptions | Made2FreightOptions | ThirdPartyFreightOptions
    >
  ) {
    const {orderFreightOptions} = this.state;
    let updatedOrderFreightOptions;
    if (orderFreightOptionsPatch.freight_type) {
      updatedOrderFreightOptions = {
        freight_type: orderFreightOptionsPatch.freight_type,
        delivery_note: orderFreightOptions?.delivery_note,
      };
    } else {
      updatedOrderFreightOptions = {
        ...orderFreightOptions,
        ...orderFreightOptionsPatch,
      } as FreightOptions;
    }
    this.setState({orderFreightOptions: updatedOrderFreightOptions});
  }

  contactValueTemplate(contact: PaContact) {
    if (contact) {
      return <div>{`${contact.first_name} ${contact.last_name}${contact.role ? '[' + contact.role + ']' : ''}`}</div>;
    } else {
      return 'empty';
    }
  }

  contactOptionTemplate(contact: PaContact) {
    return <div>{`${contact.first_name} ${contact.last_name} [${contact.role}]`}</div>;
  }

  onInputShippingAddressChange = (e: DropdownChangeParams) => {
    const orderPatch = this.state.orderPatch;
    const newAddress = e.value.address;

    const updatedOrderPatch = {
      ...orderPatch,
      shipping_address: {
        address: newAddress.street + ', ' + newAddress.suburb + ', ' + newAddress.state + ', ' + newAddress.country,
        id: e.value.id,
      },
    };
    this.setState({orderPatch: updatedOrderPatch, selectedLocation: e.value});
  };

  onItemsChange(newItems: OrderItem[]) {
    const orderPatch = this.state.orderPatch;

    const updatedOrderPatch = {
      ...orderPatch,
      items: newItems,
    };
    this.setState({
      orderPatch: updatedOrderPatch,
      clonedItems: newItems,
    });
  }

  addressSelectedOptionTemplate(location: Location) {
    if (location) {
      return (
        <div>
          {location.address.street +
            ', ' +
            location.address.suburb +
            ', ' +
            location.address.state +
            ', ' +
            location.address.country}
        </div>
      );
    }
    return 'empty';
  }

  addressOptionTemplate(location: Location) {
    return (
      <div>
        {location.address?.street +
          ', ' +
          location.address?.suburb +
          ', ' +
          location.address?.state +
          ', ' +
          location.address?.country}
      </div>
    );
  }

  renderFreightOptionsRow(
    companyFreightOptions: CompanyFreightOption[],
    orderFreightOptions?: FreightOptions,
    pickUpSharedLocations?: PaSharedLocation[],
    storageSharedLocations?: PaSharedLocation[]
  ) {
    const deliveryOptions: string[] = [];
    const pricingOptions: string[] = [];
    for (const companyFreightOption of companyFreightOptions) {
      if (companyFreightOption.includes('Made 2 Freight - Flat')) {
        if (!deliveryOptions.includes('Made 2 Freight')) {
          deliveryOptions.push('Made 2 Freight');
        }
        pricingOptions.push('Flat');
      } else if (companyFreightOption.includes('Made 2 Freight - Per Drop')) {
        if (!deliveryOptions.includes('Made 2 Freight')) {
          deliveryOptions.push('Made 2 Freight');
        }
        pricingOptions.push('Per Item');
      } else {
        deliveryOptions.push(companyFreightOption);
      }
    }
    return (
      <div className="p-field p-grid">
        <label htmlFor="fitting" className="p-col-2">
          <small>delivery</small>
        </label>
        <div className="p-col-4">
          <Dropdown
            value={orderFreightOptions?.freight_type}
            options={deliveryOptions}
            onChange={e => this.onFreightOptionsChange({freight_type: e.value})}
          />
        </div>
        {orderFreightOptions?.freight_type === 'Made 2 Freight' && (
          <>
            <label className="p-col-2">
              <small>pricing</small>
            </label>
            <div className="p-col-4">
              <Dropdown
                value={(orderFreightOptions as Made2FreightOptions)?.pricing}
                options={pricingOptions}
                onChange={e => this.onFreightOptionsChange({pricing: e.value})}
              />
            </div>
          </>
        )}
        {orderFreightOptions?.freight_type === 'Pick Up' && (
          <>
            <label className="p-col-2">
              <small>from</small>
            </label>
            <div className="p-col-4">
              <Dropdown
                value={(orderFreightOptions as PickUpFreightOptions)?.location_id}
                options={pickUpSharedLocations}
                onChange={e => this.onFreightOptionsChange({location_id: e.value})}
                optionLabel="location.name"
                optionValue="location_id"
                dataKey="location_id"
              />
            </div>
          </>
        )}
        {orderFreightOptions?.freight_type === 'Storage' && (
          <>
            <label className="p-col-2">
              <small>to</small>
            </label>
            <div className="p-col-4">
              <Dropdown
                value={(orderFreightOptions as StorageFreightOptions)?.location_id}
                options={storageSharedLocations}
                onChange={e => this.onFreightOptionsChange({location_id: e.value})}
                optionLabel="location.name"
                optionValue="location_id"
                dataKey="location_id"
              />
            </div>
          </>
        )}
        {orderFreightOptions?.freight_type === '3rd Party' && (
          <>
            <label className="p-col-2">
              <small>service</small>
            </label>
            <div className="p-col-4">
              <Dropdown
                value={(orderFreightOptions as ThirdPartyFreightOptions)?.service_name}
                options={thirdPartyFreightServicesOptions}
                onChange={e => this.onFreightOptionsChange({service_name: e.value})}
              />
            </div>
          </>
        )}
      </div>
    );
  }

  render() {
    const {order, toast} = this.props;

    const {
      orderPatch,
      locations,
      locationGroups,
      showRequestJobDialog,
      updatedOrder,
      contacts,
      selectedOwner,
      companyFittingTypes,
      companyFreightOptions,
      orderFreightOptions,
      showNewProductDefinitionDialog,
      forceCurrentProductDefinition,
      newestProductDefinitions,
      orderProductDefinitions,
      storageSharedLocations,
      pickUpSharedLocations,
    } = this.state;

    let oldDcmProductDefinitions;
    let currentDcmProductDefinitions;
    if (forceCurrentProductDefinition) {
      oldDcmProductDefinitions = orderProductDefinitions;
      currentDcmProductDefinitions = newestProductDefinitions ?? [];
    } else {
      currentDcmProductDefinitions = orderProductDefinitions ?? [];
    }

    const companyFittingOptions: DropdownOption[] = [];
    if (companyFittingTypes.length > 1) {
      companyFittingTypes.map(o => {
        companyFittingOptions.push({label: o, value: o});
      });
    }

    const currentFittingType = orderPatch.fitting_options
      ? Object.keys(orderPatch.fitting_options).includes('type')
        ? orderPatch.fitting_options?.type
        : order?.fitting_options?.type
      : order?.fitting_options?.type;

    const fittingRow =
      companyFittingTypes.length === 0 ? (
        <></>
      ) : (
        <div className="p-field p-grid">
          <label htmlFor="fitting" className="p-col-2">
            <small>request fit</small>
          </label>
          <div className="p-col-4">
            {companyFittingTypes.length === 1 ? (
              <InputSwitch
                name="fitting_type"
                onChange={e => this.onInputFittingChange(e.value ? companyFittingTypes[0] : undefined)}
                checked={currentFittingType !== undefined}
              />
            ) : (
              <Dropdown
                name="fitting_type"
                value={currentFittingType}
                options={companyFittingOptions}
                onChange={this.onInputFittingChangeDrop}
              />
            )}
          </div>
          {currentFittingType === 'made2fit' || !currentFittingType ? (
            <></>
          ) : (
            <>
              <label htmlFor="fitter" className="p-col-2">
                <small>{currentFittingType === 'internal' ? 'fitter' : 'provider'}</small>
              </label>
              <div className="p-col-4">
                <Dropdown
                  name="fitter"
                  value={this.state.selectedFittingProvider}
                  options={this.state.fittingProviderOptions}
                  onChange={this.onFittingProviderChange}
                />
              </div>
            </>
          )}
        </div>
      );

    const freightOptionsRow = this.renderFreightOptionsRow(
      companyFreightOptions,
      orderFreightOptions,
      pickUpSharedLocations,
      storageSharedLocations
    );
    const dialogBody = (
      <div className="p-fluid w-100 p-mx-2">
        <div className="p-field p-grid">
          <label htmlFor="reference" className="p-col-2">
            <small>reference</small>
          </label>
          <div className="p-col-10">
            <InputText
              value={orderPatch?.reference ?? order?.reference ?? ''}
              onChange={e => {
                const value = e.target.value;
                this.onInputReferenceChange(value);
              }}
            />
          </div>
        </div>
        {contacts.length ? (
          <div className="p-field p-grid">
            <label htmlFor="reference" className="p-col-2">
              <small>order contact</small>
            </label>
            <div className="p-col-10">
              <Dropdown
                value={selectedOwner}
                options={contacts}
                onChange={this.onInputOwnerChange}
                dataKey="id"
                optionLabel="id"
                valueTemplate={this.contactValueTemplate}
                itemTemplate={this.contactValueTemplate}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
        {freightOptionsRow}
        {fittingRow}
        {/*{orderFitting !== 'made2fit' && (*/}
        <>
          <div className="p-field p-grid">
            <label htmlFor="shipping_address" className="p-col-2">
              <small>shipping address</small>
            </label>
            <div className="p-col-10">
              {locationGroups ? (
                <Dropdown
                  name="shipping_address_grouped"
                  value={this.state.selectedLocation}
                  options={locationGroups}
                  onChange={this.onInputShippingAddressChange}
                  dataKey="id"
                  valueTemplate={this.addressSelectedOptionTemplate}
                  itemTemplate={this.addressOptionTemplate}
                  optionLabel="label"
                  optionGroupLabel="label"
                  optionGroupChildren="items"
                />
              ) : (
                <Dropdown
                  name="shipping_address_list"
                  value={this.state.selectedLocation}
                  options={locations}
                  onChange={this.onInputShippingAddressChange}
                  dataKey="id"
                  optionLabel="id"
                  valueTemplate={this.addressSelectedOptionTemplate}
                  itemTemplate={this.addressOptionTemplate}
                />
              )}
            </div>
          </div>
          <div className="p-field p-grid">
            <label htmlFor="delivery_note" className="p-col-2">
              <small>delivery note</small>
            </label>
            <div className="p-col-10">
              <InputText
                name="delivery_note"
                value={orderPatch?.freight_options?.delivery_note ?? order?.freight_options?.delivery_note ?? ''}
                onChange={e => {
                  const value = e.target.value;
                  this.onInputDeliveryNoteChange(value);
                }}
              />
            </div>
          </div>
        </>
        {/*)}*/}
        <OrderItemsComponent
          mode={'advanced_edit'}
          items={this.state.clonedItems}
          productDefinitions={currentDcmProductDefinitions}
          originalProductDefinitions={oldDcmProductDefinitions}
          onItemsChanged={this.onItemsChange}
          setInvalidItemMessagesMap={this.setInvalidItemMessagesMap}
        />
      </div>
    );

    let saveButtonLabel;
    if (order?.id) {
      saveButtonLabel = 'Save';
    } else {
      saveButtonLabel = 'Save as Estimate';
    }
    const saveAndSubmitButtonLabel = process.env.ORDER_ADD_EDIT_DIALOG_SAVE_SUBMIT_BUTTON_LABEL ?? 'Save and Submit';
    const footer = !this.state.loading ? (
      <div className={'p-d-flex p-justify-end'}>
        <Button label="Cancel" className="p-button-text" onClick={this.onHide} autoFocus />
        <Button className="p-ml-2" label={saveButtonLabel} onClick={() => this.onSave(false)} />
        {currentFittingType ? (
          <Button className="p-ml-2" label="Save and Request Job" onClick={this.onSaveAndRequestJob} />
        ) : (
          <Button className="p-ml-2" label={saveAndSubmitButtonLabel} onClick={this.onSaveAndSubmit} />
        )}
      </div>
    ) : (
      <></>
    );

    return (
      <>
        <TwoDialog
          header={order ? 'Edit Order - ' + order.id : 'Add Order'}
          showDialog={this.props.showDialog}
          onShow={this.onShow}
          onHide={this.onHide}
          loading={this.state.loading}
          footer={footer}
          style={{width: '50vw'}}
          breakpoints={{
            '1920px': '70vw',
            '992px': '80vw',
            '768px': '95vw',
          }}
        >
          {dialogBody}
        </TwoDialog>
        <RequestJobDialog
          showDialog={showRequestJobDialog}
          onHide={this.onRequestJobHide}
          toast={toast}
          estimate={updatedOrder}
        />
        <NewProductDefinitionDialog
          showDialog={showNewProductDefinitionDialog}
          onCancel={() => this.setState({showNewProductDefinitionDialog: false})}
          onYes={() =>
            this.setState({
              forceCurrentProductDefinition: true,
              showNewProductDefinitionDialog: false,
            })
          }
        />
      </>
    );
  }
}
export default AddEditOrderDialog;
