import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {library} from '@fortawesome/fontawesome-svg-core';
import {
  faSirenOn,
  faBuilding,
  faList,
  faCalendarAlt,
  faMoneyCheckAlt,
  faCalendarExclamation,
  faClock,
  faAnalytics,
  faBadge,
  faUserFriends,
  faWarehouse,
  faPlus,
  faFile,
  faFileImage,
  faTruck,
  faFileCheck,
  faFileCircleExclamation,
  faGaugeHigh,
  faCheck,
  faFileInvoiceDollar,
  faIndustryWindows,
  faDollar,
  faPlusCircle,
  faTruckContainer,
  faFilePen,
  faTape,
  faBug,
  faToolbox,
  faScrewdriverWrench,
} from '@fortawesome/pro-regular-svg-icons';
import {
  TwoAppFrame,
  AppMenuItem,
  AppContext,
  AppMenuItemTemplate,
  AppMenuItemSeparator,
  AuthService,
  ToastService,
  MessageService,
  GoogleMapsService,
  TwoToast,
} from 'two-app-ui';
import {MenuItemOptions} from 'primereact/menuitem';
import TleService from './services/TleService';
import OrdersService from './services/OrdersService';
import ContactsService from './services/ContactsService';
import LocationsService from './services/LocationsService';
import CompaniesService from './services/CompaniesService';
import {Dropdown, DropdownChangeParams} from 'primereact/dropdown';
import {DropdownOption, PaCompany, PaContact} from 'two-core';
import {Subscription} from 'rxjs';
import {messages} from './config/messages';
import './scss/App.scss';
import CompanyComponent from './components/Company/CompanyComponent';
import DashboardComponent from './components/Dashboard/DashboardComponent';
import OrderComponent from './components/Order/OrderComponent';
import M2OUsersService from './services/M2OUsersService';
import InvoiceComponent from './components/Invoice/InvoiceComponent';
import ProductsService from './services/ProductsService';
import AllContainersComponent from './components/Containers/ContainersListComponent';
import ContainersService from './services/ContainersService';
import JobsService from './services/JobsService';
import JobDocumentsService from './services/JobDocumentsService';
import config from './config/config';
import DraftsService from './services/DraftsService';
import DraftListComponent from './components/Drafts/DraftListComponent';
import DraftComponent from './components/Draft/DraftComponent';
import {localStorageAttributes} from './config/localStorageAttributes';
import JobListComponent from './components/Jobs/JobListComponent';
import AppointmentsService from './services/AppointmentsService';
import JobComponent from './components/Job/JobComponent';
import OrderListComponent from './components/Orders/OrderListComponent';
import {Toast} from 'primereact/toast';
import {SharedLocationsService} from './services/SharedLocationsService';

library.add(
  faSirenOn,
  faBuilding,
  faList,
  faCalendarAlt,
  faMoneyCheckAlt,
  faCalendarExclamation,
  faClock,
  faAnalytics,
  faBadge,
  faUserFriends,
  faWarehouse,
  faPlus,
  faFile,
  faFileImage,
  faTruck,
  faFileCheck,
  faFileCircleExclamation,
  faGaugeHigh,
  faFileInvoiceDollar,
  faCheck,
  faDollar,
  faPlusCircle,
  faTruckContainer
);

const authService = new AuthService();
const companiesService = new CompaniesService(authService);
const tleService = new TleService(authService);
const ordersService = new OrdersService(authService);
const contactsService = new ContactsService(authService);
const locationsService = new LocationsService(authService);
const sharedLocationsService = new SharedLocationsService(authService);
const usersService = new M2OUsersService(authService);
const toastService = new ToastService();
const productsService = new ProductsService(authService);
const containersService = new ContainersService(authService);
const googleMapsService = new GoogleMapsService(config().googleApiKey ?? '');
const jobsService = new JobsService(authService);
const jobDocumentsService = new JobDocumentsService(authService);
const draftsService = new DraftsService(authService);
const appointmentsService = new AppointmentsService(authService);
const toastRef = React.createRef<Toast>();
const twoToast = new TwoToast(toastRef);

interface State {
  companiesOptions: DropdownOption[];
  currentCompanyId?: string;
  companies: PaCompany[];
  menuItems: AppMenuItem[];
}

class App extends React.Component<{}, State> {
  static contextType = AppContext;
  subscription: Subscription = new Subscription();

  constructor(props: {}) {
    super(props);

    this.state = {
      companiesOptions: [],
      companies: [],
      menuItems: [],
    };

    this.onCompanyChange = this.onCompanyChange.bind(this);
  }

  async componentDidMount() {
    this.subscription = MessageService.getMessage().subscribe(async message => {
      if (message === 'loggedin') {
        this.loadData();
      }
    });
    this.loadData();
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  getMenuItems(currentCompany: PaCompany): AppMenuItem[] {
    const menuItems: AppMenuItem[] = [];
    const userRole = localStorage.getItem(localStorageAttributes.currentRole);

    const doesFitting =
      currentCompany.fits_for_others ||
      (currentCompany.fitting_types && currentCompany.fitting_types.includes('Internal'));

    const usesFitting = currentCompany.fitting_types && currentCompany.fitting_types !== '';

    menuItems.push(
      {
        label: 'Dashboard',
        faIcon: faGaugeHigh,
        badgeId: 'dashboardBadge',
        to: '/dashboard',
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
      },
      {
        separator: true,
        template: () => {
          return <AppMenuItemSeparator />;
        },
      }
    );

    const myProfileSubMenu: AppMenuItem[] = [];
    myProfileSubMenu.push({
      label: 'Company',
      faIcon: faBuilding,
      badgeId: 'alarmBadge',
      to: `/company/${currentCompany.id}`,
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
    });
    if (userRole === 'admin' && currentCompany.does_orders) {
      myProfileSubMenu.push({
        label: 'Price List',
        faIcon: faMoneyCheckAlt,
        to: '/price-list',
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
      });
    }

    menuItems.push({
      label: 'My Profile',
      expanded: true,
      className: 'my-profile',
      items: myProfileSubMenu,
    });

    if (currentCompany.does_orders) {
      const ordersMenuItems = [];
      ordersMenuItems.push({
        label: 'All',
        faIcon: faList,
        to: '/orders',
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
      });
      if (currentCompany?.does_quotes) {
        ordersMenuItems.push({
          label: 'Drafts',
          faIcon: faFilePen,
          to: '/drafts',
          template: (item: AppMenuItem, options: MenuItemOptions) => {
            return <AppMenuItemTemplate item={item} options={options} />;
          },
        });
      }
      ordersMenuItems.push({
        label: 'Estimates',
        faIcon: faFile,
        to: '/estimates',
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
      });

      if (usesFitting || doesFitting) {
        ordersMenuItems.push({
          label: 'Measures',
          faIcon: faTape,
          to: '/measures',
          template: (item: AppMenuItem, options: MenuItemOptions) => {
            return <AppMenuItemTemplate item={item} options={options} />;
          },
        });
      }
      ordersMenuItems.push(
        {
          label: 'Drawings',
          faIcon: faFileImage,
          to: '/drawings',
          template: (item: AppMenuItem, options: MenuItemOptions) => {
            return <AppMenuItemTemplate item={item} options={options} />;
          },
        },
        {
          label: 'In Production',
          faIcon: faIndustryWindows,
          to: '/production',
          template: (item: AppMenuItem, options: MenuItemOptions) => {
            return <AppMenuItemTemplate item={item} options={options} />;
          },
        },
        {
          label: 'In Shipping',
          faIcon: faTruck,
          to: '/shipping',
          template: (item: AppMenuItem, options: MenuItemOptions) => {
            return <AppMenuItemTemplate item={item} options={options} />;
          },
        },
        {
          label: 'Delivered',
          faIcon: faFileCheck,
          to: '/delivered',
          template: (item: AppMenuItem, options: MenuItemOptions) => {
            return <AppMenuItemTemplate item={item} options={options} />;
          },
        }
      );
      if (usesFitting || doesFitting) {
        ordersMenuItems.push({
          label: 'Installs',
          faIcon: faScrewdriverWrench,
          to: '/installs',
          template: (item: AppMenuItem, options: MenuItemOptions) => {
            return <AppMenuItemTemplate item={item} options={options} />;
          },
        });
      }
      ordersMenuItems.push({
        label: 'Repairs',
        faIcon: faBug,
        to: '/repairs',
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
      });
      if (usesFitting || doesFitting) {
        ordersMenuItems.push({
          label: 'Service Calls',
          faIcon: faToolbox,
          to: '/service-calls',
          template: (item: AppMenuItem, options: MenuItemOptions) => {
            return <AppMenuItemTemplate item={item} options={options} />;
          },
        });
      }
      menuItems.push({
        label: 'Orders',
        expanded: true,
        className: 'my-orders',
        items: ordersMenuItems,
      });
    }
    if (doesFitting) {
      const jobsMenuItems = [];
      jobsMenuItems.push({
        label: 'Requested',
        faIcon: faTape,
        to: '/requested-jobs',
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
      });
      jobsMenuItems.push({
        label: 'In Progress',
        faIcon: faTape,
        to: '/in-progress-jobs',
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
      });
      jobsMenuItems.push({
        label: 'Measure Reviews',
        faIcon: faTape,
        to: '/measure-review-jobs',
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
      });
      // jobsMenuItems.push({
      //   label: 'In Production & Shipping',
      //   faIcon: faIndustry,
      //   to: '/in-production-in-shipping-jobs',
      //   template: (item: AppMenuItem, options: MenuItemOptions) => {
      //     return <AppMenuItemTemplate item={item} options={options} />;
      //   },
      // });
      // jobsMenuItems.push({
      //   label: 'Delivered',
      //   faIcon: faPersonCarryBox,
      //   to: '/delivered-jobs',
      //   template: (item: AppMenuItem, options: MenuItemOptions) => {
      //     return <AppMenuItemTemplate item={item} options={options} />;
      //   },
      // });
      // jobsMenuItems.push({
      //   label: 'Installs',
      //   faIcon: faBlinds,
      //   to: '/install-jobs',
      //   template: (item: AppMenuItem, options: MenuItemOptions) => {
      //     return <AppMenuItemTemplate item={item} options={options} />;
      //   },
      // });
      // jobsMenuItems.push({
      //   label: 'Install Reviews',
      //   faIcon: faBlinds,
      //   to: '/install-review-jobs',
      //   template: (item: AppMenuItem, options: MenuItemOptions) => {
      //     return <AppMenuItemTemplate item={item} options={options} />;
      //   },
      // });
      jobsMenuItems.push({
        label: 'All',
        faIcon: faList,
        to: '/jobs',
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
      });
      menuItems.push(
        {
          label: 'Jobs',
          expanded: true,
          items: jobsMenuItems,
        },
        {
          separator: true,
          template: () => {
            return <AppMenuItemSeparator />;
          },
        }
      );
    }
    menuItems.push({
      label: 'Containers',
      faIcon: faTruckContainer,
      badgeId: 'containersBadge',
      to: '/containers',
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
    });
    if (userRole === 'admin' && currentCompany.does_orders) {
      menuItems.push({
        label: 'Invoices',
        faIcon: faFileInvoiceDollar,
        badgeId: 'invoiceBadge',
        to: '/invoice',
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
      });
    }
    return menuItems;
  }

  async loadData() {
    if (await authService.isSessionValid()) {
      contactsService
        .getMe()
        .then(data => {
          if (data) {
            localStorage.setItem(localStorageAttributes.myContact, JSON.stringify(data as unknown as PaContact));
          }
        })
        .catch(e => {
          console.log('No Contact => Maybe TWO. ' + e);
        });
      companiesService
        .getCompanies({
          orderBys: [JSON.stringify({field: 'name', direction: 'ASC'})],
          aggregate: ['fitting_providers', 'company_contacts'],
        })
        .then(companiesApiListResponse => {
          let companyListOptions: DropdownOption[] = [];
          if (companiesApiListResponse.records) {
            const companiesList = companiesApiListResponse.records as PaCompany[];
            companyListOptions = companiesList.map(company => {
              return {
                value: company.id ?? '',
                label: `${company.name}${company.trading_as ? ` [ ${company.trading_as} ]` : ''}`,
              } as DropdownOption;
            });

            const currentCompanyId = localStorage.getItem(localStorageAttributes.currentCompanyId);
            let selectedCompany = companiesList.find(company => currentCompanyId === company.id);
            if (currentCompanyId === null || !selectedCompany) {
              selectedCompany = companiesList[0];
              localStorage.setItem(localStorageAttributes.currentCompanyId, selectedCompany.id);
            }
            localStorage.setItem(
              localStorageAttributes.doesQuotes,
              JSON.stringify(selectedCompany.does_quotes ?? false)
            );
            localStorage.setItem(localStorageAttributes.fittingTypes, selectedCompany.fitting_types ?? '');
            localStorage.setItem(localStorageAttributes.currentCompanyState, selectedCompany.state);
            localStorage.setItem(localStorageAttributes.currentRole, selectedCompany.my_role);
            localStorage.setItem(localStorageAttributes.currentCompany, JSON.stringify(selectedCompany));

            const menuItems = this.getMenuItems(selectedCompany);

            this.setState({
              companiesOptions: companyListOptions,
              currentCompanyId: selectedCompany?.id,
              companies: companiesList,
              menuItems: menuItems,
            });

            MessageService.sendMessage(messages.topSelectionChanged);
            MessageService.sendMessage(messages.refreshAppMenu);
          } else {
            // @todo: ask the user to re-try / to login in again, as they have no companies
            throw new Error('no companies');
          }
        })
        .catch(error => {
          // @todo: ask the user to re-try
          throw new Error('be error ' + error);
        });
    }
  }

  async onCompanyChange(option: DropdownChangeParams) {
    const newlySelectedCompanyId = option.value as string;
    const newCompany = this.state.companies.find(comp => comp.id === newlySelectedCompanyId);
    if (newCompany) {
      localStorage.setItem(localStorageAttributes.currentRole, newCompany.my_role);
    }
    const menuItems = this.getMenuItems(newCompany!);
    this.setState({
      currentCompanyId: newlySelectedCompanyId,
      menuItems: menuItems,
    });
    localStorage.setItem(localStorageAttributes.currentCompanyId, newlySelectedCompanyId);
    localStorage.setItem(localStorageAttributes.doesQuotes, JSON.stringify(newCompany?.does_quotes ?? false));
    localStorage.setItem(localStorageAttributes.fittingTypes, newCompany?.fitting_types ?? '');
    localStorage.setItem(localStorageAttributes.fittsForOthers, newCompany?.fits_for_others ? 'true' : 'false');
    localStorage.setItem(localStorageAttributes.currentCompanyState, newCompany?.state ?? '');
    localStorage.setItem(localStorageAttributes.currentCompany, JSON.stringify(newCompany));
    MessageService.sendMessage({
      name: messages.topSelectionChanged,
      value: newlySelectedCompanyId,
    });
    MessageService.sendMessage(messages.refreshAppMenu);
  }

  render() {
    const {currentCompanyId, companiesOptions, menuItems} = this.state;
    const values = {
      authService: authService,
      companiesService: companiesService,
      tleService: tleService,
      ordersService: ordersService,
      contactsService: contactsService,
      locationsService: locationsService,
      sharedLocationsService: sharedLocationsService,
      toastService: toastService,
      usersService: usersService,
      productsService: productsService,
      containersService: containersService,
      googleMapsService: googleMapsService,
      jobsService: jobsService,
      jobDocumentsService: jobDocumentsService,
      draftsService: draftsService,
      appointmentsService: appointmentsService,
      twoToast: twoToast,
    };

    return (
      <>
        <TwoAppFrame menuItems={menuItems} contextValues={values}>
          <div className="topframe">
            <div className="dropdown-top">
              <Dropdown
                filter
                value={currentCompanyId}
                options={companiesOptions}
                onChange={this.onCompanyChange}
                placeholder="Select option"
                filterPlaceholder="Filter by trading as and name"
                optionLabel="label"
                optionValue="value"
              />
            </div>
          </div>
          <>
            <Switch>
              <Route exact path="/">
                <Redirect to="/dashboard" />
              </Route>
              <Route path="/dashboard">
                <DashboardComponent />
              </Route>
              <Route path="/company">
                <CompanyComponent />
              </Route>
              <Route path="/order/:id">
                <OrderComponent />
              </Route>
              <Route path="/orders">
                <OrderListComponent mode={'All'} />
              </Route>
              <Route path="/quotes">
                <></>
              </Route>
              <Route path="/drafts">
                <DraftListComponent />
              </Route>
              <Route path="/draft/:id">
                <DraftComponent />
              </Route>
              <Route path="/estimates">
                <OrderListComponent mode={'Estimate'} />
              </Route>
              <Route path="/measures">
                <OrderListComponent mode={'Measure'} />
              </Route>
              <Route path="/drawings">
                <OrderListComponent mode={'Drawings'} />
              </Route>
              <Route path="/production">
                <OrderListComponent mode={'In Production'} />
              </Route>
              <Route path="/shipping">
                <OrderListComponent mode={'In Shipping'} />
              </Route>
              <Route path="/delivered">
                <OrderListComponent mode={'Delivered'} />
              </Route>
              <Route path="/price-list"></Route>
              <Route path="/containers">
                <AllContainersComponent />
              </Route>
              <Route path="/invoice">
                <InvoiceComponent />
              </Route>
              <Route path="/jobs">
                <JobListComponent mode={'All'} />
              </Route>
              <Route path="/requested-jobs">
                <JobListComponent mode={'Requested'} />
              </Route>
              <Route path="/job/:id">
                <JobComponent />
              </Route>
              <Route path="/in-progress-jobs">
                <JobListComponent mode={'In Progress'} />
              </Route>
              <Route path="/measure-review-jobs">
                <JobListComponent mode={'Measure Reviews'} />
              </Route>
              {/*<Route path="/in-production-in-shipping-jobs">*/}
              {/*  <JobListComponent mode={'In Production & Shipping'} />*/}
              {/*</Route>*/}
              {/*<Route path="/delivered-jobs">*/}
              {/*  <JobListComponent mode={'Delivered'} />*/}
              {/*</Route>*/}
              {/*<Route path="/install-jobs">*/}
              {/*  <JobListComponent mode={'Installs'} />*/}
              {/*</Route>*/}
              {/*<Route path="/install-review-jobs">*/}
              {/*  <JobListComponent mode={'Install Reviews'} />*/}
              {/*</Route>*/}
              {/*<Route path="/:any">*/}
              {/*  <Redirect to="/dashboard" />*/}
              {/*</Route>*/}
            </Switch>
          </>
        </TwoAppFrame>
        <Toast ref={toastRef} />
      </>
    );
  }
}

export default App;
